import React from "react"
import cx from "clsx"

import styles from "./spinner.module.scss"

interface SpinnerProps {
  className?: string
}

const Spinner = ({ className }: SpinnerProps) => {
  return (
    <div className={cx(styles["root"], className)}>
      <svg className={styles["svg"]} viewBox="25 25 50 50">
        <circle className={styles["circle"]} cx={50} cy={50} r={20} fill="none" />
      </svg>
    </div>
  )
}

export { Spinner }
