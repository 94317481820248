import React from "react"
import { PROXY_SERVER_DOMAIN, REPLACABLE_DOMAIN } from "@app/features/google-image-service-status/constants"

const useGoogleImageSrc = (src, isGoogleServiceAvailable) => {
  const [imageSrc, setImageSrc] = React.useState(src)

  React.useEffect(() => {
    const replacableDomainRegExp = new RegExp(REPLACABLE_DOMAIN, "g")
    isGoogleServiceAvailable ? setImageSrc(src) : setImageSrc(src?.replace(replacableDomainRegExp, PROXY_SERVER_DOMAIN))
  }, [isGoogleServiceAvailable, src])

  return imageSrc
}

export { useGoogleImageSrc }
