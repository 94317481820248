import { PublicGalleryInterface } from "@app/features/gallery/types"

function getTags(galleries: PublicGalleryInterface[]) {
  // Собираем все активные теги (Object) в один массив
  const tagsArray = []
  galleries
    .map((gallery) => gallery?.tags)
    .forEach((tagArray) => {
      tagsArray.push(...tagArray.map((tag) => tag))
    })

  // Оставляем только уникальные (неповторяющиеся) теги
  const uniqueTags = tagsArray.filter((value, index) => {
    const _value = JSON.stringify(value)
    return (
      index ===
      tagsArray.findIndex((obj) => {
        return JSON.stringify(obj) === _value
      })
    )
  })

  // Сортируем названия тегов (А-Я)
  const sortedTagsValue = uniqueTags
    .map((tag) => tag.value)
    .sort((firstCategory, secondCategory) => {
      const firstCategoryFirstLetter = firstCategory.charAt(0).toLowerCase()
      const secondCategoryFirstLetter = secondCategory.charAt(0).toLowerCase()

      if (firstCategoryFirstLetter < secondCategoryFirstLetter) {
        return -1
      }
      if (firstCategoryFirstLetter > secondCategoryFirstLetter) {
        return 1
      }
      return 0
    })

  // Собираем массив из отсортированных тегов = { id, value, handle }
  const sortedTags = sortedTagsValue.map((tag) => {
    const tagSort = uniqueTags.find((item) => item.value === tag)

    return { id: tagSort.id, value: tagSort.value, handle: tagSort?.handle }
  })

  return sortedTags
}

export { getTags }
