import { FC } from "react"
import { useI18n } from "next-localization"

import { GeneralSettingsInterface } from "@app/features/general-settings/types/general-settings"
import { getFormattedPhone } from "@app/features/photographer-contacts/util/format-phone"
import { Button } from "@app/ui/button"
import { SocialNetworks } from "@app/ui/social-networks"
import { ESocialNetworks } from "@app/ui/social-networks/constants"

import { QRCodeContact } from "../photographer-qr/photographer-qr"
import { SaveContactsButton } from "../save-contacts/save-contacts"

import styles from "./business-card.module.scss"

type Props = {
  isMobile?: boolean
  handleRedirect?: (type: "whatsapp" | "telegram", value: string) => void
  websiteUrl?: string
  hasSocialMedia?: string
  generalSettings: GeneralSettingsInterface
  onQrOpen: () => void
  onQrClose: () => void
  isQrOpen?: boolean
}

const BusinessCard: FC<Props> = ({
  generalSettings,
  websiteUrl,
  hasSocialMedia,
  handleRedirect,
  isMobile,
  isQrOpen,
  onQrOpen,
  onQrClose,
}) => {
  const i18n = useI18n()

  const hasContacts = generalSettings.phone || generalSettings.whatsapp || generalSettings.telegram

  const handleShowQrCode = () => {
    if (generalSettings.name && hasContacts) {
      onQrOpen()
    }
  }

  const handleCloseQrCode = () => {
    onQrClose()
  }

  return (
    <div className={isMobile ? styles["mobile-wrapper"] : styles["desktop-wrapper"]}>
      <div className={styles["header"]}>
        {generalSettings.name && <h2 className={styles["title"]}>{generalSettings.name}</h2>}
        {generalSettings.photographyDirection && (
          <h3 className={styles["direction"]}>{generalSettings.photographyDirection}</h3>
        )}
      </div>
      {isQrOpen && !isMobile ? (
        <QRCodeContact
          name={generalSettings.name}
          phone={generalSettings.phone}
          whatsapp={generalSettings.whatsapp}
          telegram={generalSettings.telegram}
          onBackClick={handleCloseQrCode}
        />
      ) : (
        <div className={styles["content"]}>
          {hasContacts && (
            <>
              {generalSettings.phone && (
                <a className={styles["phone"]} href={`tel:${generalSettings.phone}`} type="tel">
                  {getFormattedPhone(generalSettings.phone)}
                </a>
              )}
              {generalSettings.whatsapp && (
                <Button
                  className={styles["visit-card-social"]}
                  skin="light"
                  onClick={() => handleRedirect("whatsapp", generalSettings.whatsapp)}
                >
                  WhatsApp
                </Button>
              )}
              {generalSettings.telegram && (
                <Button
                  className={styles["visit-card-social"]}
                  skin="light"
                  onClick={() => handleRedirect("telegram", generalSettings.telegram)}
                >
                  Telegram
                </Button>
              )}
              {isMobile ? (
                <SaveContactsButton
                  phone={generalSettings.phone}
                  whatsapp={generalSettings.whatsapp}
                  telegram={generalSettings.telegram}
                  name={generalSettings.name}
                >
                  {i18n.t("visitCardModal.copy_contact")}
                </SaveContactsButton>
              ) : (
                <Button className={styles["copy-contact"]} skin="light" onClick={handleShowQrCode}>
                  {i18n.t("visitCardModal.copy_contact")}
                </Button>
              )}
            </>
          )}

          {hasSocialMedia && (
            <div className={styles["social-media"]}>
              <SocialNetworks
                className={styles["social-list"]}
                excludeIcons={[ESocialNetworks.Telegram, ESocialNetworks.WhatsApp]}
              />
            </div>
          )}

          {generalSettings.site && (
            <a className={styles["site"]} href={generalSettings.site} target="_blank" rel="noopener noreferrer">
              {websiteUrl}
            </a>
          )}
        </div>
      )}
    </div>
  )
}

export { BusinessCard }
