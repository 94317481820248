import { IconProps } from "@app/types"

const VisitCardArrow = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={fill} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.47355 11.1189C1.27104 10.289 1.55482 8.43808 2.95073 8.00662L18.6177 3.1641C19.9619 2.74863 21.2219 4.00869 20.8065 5.35284L15.964 21.0198C15.5325 22.4157 13.6815 22.6995 12.8517 21.497L8.61408 15.3565L2.47355 11.1189ZM10.1255 14.9057L14.0862 20.645C14.2048 20.8168 14.4692 20.7763 14.5308 20.5769L19.3734 4.90988C19.4327 4.71786 19.2527 4.53785 19.0607 4.5972L3.39368 9.43973C3.19428 9.50136 3.15373 9.76578 3.32553 9.88434L9.06485 13.845L12.7287 10.1812C13.0216 9.8883 13.4965 9.8883 13.7894 10.1812C14.0823 10.4741 14.0823 10.949 13.7894 11.2419L10.1255 14.9057Z"
        fill={fill}
      />
    </svg>
  )
}

export { VisitCardArrow }
