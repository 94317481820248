import * as React from "react"
import { useRouter } from "next/router"

import { useSelectedTag } from "@app/features/tags/store/hooks"

import { getTags } from "@app/features/tags/utils/get-tags"

import { PublicGalleryInterface } from "@app/features/gallery/types"

import { Container } from "@app/ui/container"
import { MobileTagsSelector } from "./mobile-tags-selector"
import { DesktopTagsSelector } from "./desktop-tags-selector"
import { DEFAULT_TAG } from "@app/features/tags/constants"

import styles from "./tags-selector.module.scss"

interface TagSelectorProps {
  galleries: PublicGalleryInterface[]
}

const TagsSelector = ({ galleries }: TagSelectorProps) => {
  const router = useRouter()
  const { selectedTag, setSelectedTag } = useSelectedTag()
  const tags = getTags(galleries)
  const hasTags = !!tags.length
  const [newRouteQuery, setNewRouteQuery] = React.useState(null)

  React.useEffect(() => {
    const hasTagsQuery = router.query?.tag

    if (hasTagsQuery) {
      const tag = tags.find((item) => {
        if (item.id === hasTagsQuery) {
          setNewRouteQuery(item.handle)
          return item
        }

        if (item?.handle === hasTagsQuery) return item
      })

      setSelectedTag(tag)
    }

    if (!hasTagsQuery) {
      setSelectedTag(null)
    }
  }, [router.query])

  React.useEffect(() => {
    if (newRouteQuery) router.push(`/?tag=${newRouteQuery}`, undefined, { shallow: true })
  }, [newRouteQuery])

  function handleSelectTag(tag: any) {
    const tagFormatted = JSON.parse(tag)
    const query = tagFormatted?.handle || tagFormatted.id
    const defaultQuery = tagFormatted?.handle ? DEFAULT_TAG.handle : DEFAULT_TAG.id

    if (query === defaultQuery) {
      setSelectedTag(null)
      router.push("/", undefined, { shallow: true })

      return
    }

    const queryParam = `/?tag=${query}`

    router.push(queryParam, undefined, { shallow: true })
    setSelectedTag(tagFormatted)
  }

  if (!hasTags) return null

  return (
    <Container>
      <div className={styles["root"]}>
        <DesktopTagsSelector
          items={tags}
          onSelect={handleSelectTag}
          selectedItem={selectedTag}
          defaultTag={DEFAULT_TAG}
        ></DesktopTagsSelector>
        <MobileTagsSelector
          items={tags}
          onSelect={handleSelectTag}
          selectedItem={selectedTag}
          defaultTag={DEFAULT_TAG}
        ></MobileTagsSelector>
      </div>
    </Container>
  )
}

export { TagsSelector }
