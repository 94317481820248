import * as React from "react"
import * as ReactDOM from "react-dom"

interface PortalInterface {
  children: React.ReactNode
  as?: string
  id?: string
}

const Portal = ({ children, as = "div", id }: PortalInterface) => {
  const [renderNode] = React.useState(() => window.document.createElement(as))

  React.useEffect(() => {
    window.document.body.appendChild(renderNode)
    renderNode.setAttribute("role", "dialog")

    if (id) {
      renderNode.setAttribute("id", id)
    }

    return () => {
      window.document.body.removeChild(renderNode)
    }
  }, [])

  return ReactDOM.createPortal(children, renderNode)
}

export { Portal }
