import { FC, PropsWithChildren } from "react"

import { downloadVCard, generateVCard } from "@app/features/photographer-contacts/util/download"
import { Button } from "@app/ui/button"

import styles from "./save-contacts.module.scss"

type Props = PropsWithChildren<{
  name: string
  phone?: string
  whatsapp?: string
  telegram?: string
}>

const SaveContactsButton: FC<Props> = ({ phone, name, whatsapp, telegram, children }) => {
  const handleSaveContact = () => {
    const vCardText = generateVCard(name, phone, whatsapp, telegram)
    if (vCardText) {
      downloadVCard(vCardText, name)
    } else {
      return null
    }
  }

  return (
    <Button className={styles["copy-contact"]} skin="light" onClick={handleSaveContact}>
      {children}
    </Button>
  )
}

export { SaveContactsButton }
