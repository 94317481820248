import cn from "clsx"

import styles from "./desktop-tags-selector.module.scss"

const DesktopTagsSelector = ({ items, onSelect, selectedItem, defaultTag }) => {
  return (
    <ul className={styles["list"]}>
      {items.length && (
        <li
          className={cn(styles["option"], {
            [styles["default"]]: !selectedItem,
          })}
          key={defaultTag.id}
          onClick={() => onSelect(JSON.stringify(defaultTag))}
        >
          {defaultTag.value}
        </li>
      )}
      {items.map((item) => (
        <li
          className={cn(styles["option"], {
            [styles["active"]]: selectedItem?.id === item?.id,
          })}
          key={item.id}
          onClick={() => onSelect(JSON.stringify(item))}
        >
          {item.value}
        </li>
      ))}
    </ul>
  )
}

export { DesktopTagsSelector }
