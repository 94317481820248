import styles from "./mobile-tags-selector.module.scss"

const MobileTagsSelector = ({ items, onSelect, selectedItem, defaultTag }) => {
  return (
    <select
      className={styles["root"]}
      onChange={(event) => {
        return onSelect(event.target.value)
      }}
      value={selectedItem ? JSON.stringify(selectedItem) : JSON.stringify(defaultTag)}
    >
      {items.length && (
        <option key={defaultTag.id} value={JSON.stringify(defaultTag)}>
          {defaultTag.value}
        </option>
      )}
      {items.map((item) => (
        <option key={item.id} value={JSON.stringify(item)}>
          {item.value}
        </option>
      ))}
    </select>
  )
}

export { MobileTagsSelector }
