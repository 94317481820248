import * as React from "react"
import cx from "clsx"

import { useKeyPress } from "@app/utils"

import { Portal } from "@app/ui/portal"
import { Cross } from "@app/ui/icons"

import styles from "./modal.module.scss"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

interface SimpleModalProps {
  children: JSX.Element
  open: boolean
  onClose: () => void
  crossClassName?: string
}

const SimpleModal = ({ children, open, onClose, crossClassName }: SimpleModalProps) => {
  useKeyPress("Escape", onClose)

  const innerRef = React.useRef<HTMLDivElement | null>()

  function handleBlackoutClick(event: any) {
    if (innerRef.current?.contains(event.target)) return

    const isLeftButtonClick = event.buttons === 1

    if (isLeftButtonClick) {
      onClose()
    }
  }

  if (!open) return null

  return (
    <Portal>
      <aside className={cx(styles["blackout"], styles["open"])} onMouseDown={handleBlackoutClick}>
        <div className={cx(styles["modal"])} ref={innerRef}>
          <button className={cx(styles["cross"], crossClassName)} onClick={onClose}>
            <IconWrapper
              light={<Cross width={16} height={16} />}
              dark={<Cross stroke="#fff" width={16} height={16} />}
            />
          </button>
          <div className={styles["inner"]}>{children}</div>
        </div>
      </aside>
    </Portal>
  )
}

export { SimpleModal }
