import React, { FunctionComponent, ReactElement } from "react"

import { GoogleImageServiceStatusContext } from "@app/features/google-image-service-status/contexts"
import { useGoogleImageSrc } from "@app/features/google-image-service-status/hooks/use-google-image-src"

const GoogleImage: FunctionComponent<any> = React.forwardRef(
  ({ src, isProtected = false, contextModal = true, isPostfix = false, ...rest }: any, ref: any): ReactElement => {
    const { isAvailable, postfix } = React.useContext(GoogleImageServiceStatusContext)
    const imageSrc = useGoogleImageSrc(src, isAvailable)
    const imgPostfix = isAvailable && isPostfix ? postfix : ""

    return ref ? (
      <img src={`${imageSrc}${imgPostfix}`} ref={ref} data-protect={isProtected} {...rest} />
    ) : (
      <img src={`${imageSrc}${imgPostfix}`} data-protect={isProtected} {...rest} />
    )
  }
)
GoogleImage.displayName = "GoogleImage"

export { GoogleImage }
