const generateVCard = (name: string, phone?: string, whatsapp?: string, telegram?: string) => {
  const primaryPhone = phone || whatsapp || telegram

  if (!name || !primaryPhone) {
    return ""
  }

  return [
    "BEGIN:VCARD",
    "VERSION:3.0",
    `N:;${name};;;`,
    `FN:${name}`,
    phone ? `TEL;TYPE=CELL:${phone}` : "",
    whatsapp ? `TEL;TYPE=WORK,VOICE:${whatsapp}` : "",
    telegram ? `TEL;TYPE=HOME,VOICE:${telegram}` : "",
    "END:VCARD",
  ]
    .filter(Boolean)
    .join("\n")
}

const downloadVCard = (vCardText: string, name: string) => {
  const element = document.createElement("a")
  const file = new Blob([vCardText], { type: "text/vcard;charset=utf-8" })
  element.href = URL.createObjectURL(file)
  element.download = `${name}.vcf`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export { downloadVCard, generateVCard }
